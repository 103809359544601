import React, { useEffect, useState } from 'react'
import "./Users.css"
import Layout from '../components/Layout';
import CustomText from '../components/CustomText';
import { database } from '../firebase';
import { onValue, ref, set, update } from 'firebase/database';
export default function Archiveds() {
    const [users, setUsers] = useState([])
    const refUsers = ref(database, 'users');
    const [selectedUser, setSelectedUsers] = useState([])
    useEffect(() => {
        console.log(selectedUser)
    }, [selectedUser])
    useEffect(() => {
        onValue(refUsers, (snapshot) => {
            const data = snapshot.val();

            const arrayDeObjetos = Object.entries(data).map(([id, value]) => {
                return {
                    ...value,
                    id
                };
            });

            const objetosConArchived = arrayDeObjetos.filter(objeto => objeto.archived === true);

            const orderedUsers = objetosConArchived.sort((a, b) => b.counterId - a.counterId);
            setUsers(orderedUsers)
        });
    }, [])
    const handleCheckboxChange = (e, id) => {
        if (e.target.checked) {
            setSelectedUsers(prevState => [...prevState, id]);
        } else {
            setSelectedUsers(prevState => prevState.filter(itemId => itemId !== id));
        }
    };

    function formatearFecha(unixTime) {
        // Convertir el unix time a milisegundos para JavaScript
        const fecha = new Date(unixTime * 1000);
        const opciones = {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        };
        return fecha.toLocaleDateString('es-ES', opciones);
    }

    function updateArchivedUser() {
        selectedUser.forEach((user) => update(ref(database, 'users/' + user), {
            archived: false
        }))


    }

    return (
        <>
            <Layout >
                <div style={{ marginBottom: '10px' }}>
                    <button onClick={() => updateArchivedUser()}>
                        <CustomText>
                            Desarchivar Seleccionados
                        </CustomText>
                    </button>
                </div>
                <table className="xp-table">
                    <thead>
                        <tr>
                            <th>Select</th>
                            <th>ID</th>
                            {/* <th>Notes</th> */}
                            <th>IP</th>
                            {/* <th>Browser</th> */}
                            <th>Mail</th>
                            {/* <th>Active</th> */}
                            <th>Password</th>
                            <th>Online</th>
                            <th>Joined date</th>
                            <th>Archived</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user) => <tr>
                            <td key={user.id}><input type='checkbox' style={{ width: '10px' }} onChange={e => handleCheckboxChange(e, user.id)} /></td>
                            <td>{user.counterId}</td>
                            <td>{user.ip}</td>
                            <td>{user.email}</td>
                            <td>{user.password}</td>
                            <td>{user.online ? 'Yes' : 'No'}</td>
                            <td>{formatearFecha(user.date)}</td>
                            <td>{user.archived ? "Yes" : 'No'}</td>
                            <td><a href={`/users/${user.id}`}><img src='./lupita.png' width='50px' class='lupita-animada' alt='' /></a></td>
                        </tr>)}
                        <tr>
                        </tr>
                    </tbody>
                </table>
            </Layout>



        </>
    )
}
