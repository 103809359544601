import React from 'react'
import './styles/CustomWindow.css'
export default function CustomWindow({ children, style, title }) {
    return (
        <div style={style} className="window-custom">
            <div className="title-bar-custom">
                <div className="title-bar-text" style={{ fontSize: '20px' }}>{title}</div>
                <div className="title-bar-controls">
                    <button aria-label="Minimize" />
                    <button aria-label="Maximize" />
                    <button aria-label="Close" />
                </div>
            </div>

            <div className="window-body">

                <div className="field-row" style={{ justifyContent: "center" }}>
                    {children}
                </div>
            </div>
        </div>
    )
}
