// hooks/useUsers.js
import { useState, useEffect } from 'react';
import { database } from '../firebase';
import { onValue, ref, update } from 'firebase/database';

export function useUsers() {
    const [users, setUsers] = useState([]);
    const usersRef = ref(database, 'users');
    const [selectedUserIds, setSelectedUserIds] = useState([]);

    useEffect(() => {
        fetchUsersData();
    }, []);

    const fetchUsersData = () => {
        onValue(usersRef, (snapshot) => {
            const rawData = snapshot.val();
            if (rawData) {
                const usersArray = Object.entries(rawData)
                    .map(([id, userData]) => ({ ...userData, id }))
                    .filter(user => !user.archived)
                    .sort((a, b) => b.counterId - a.counterId);
                setUsers(usersArray);
            }
        });
    };

    const handleCheckboxChange = (e, id) => {
        if (e.target.checked) {
            setSelectedUserIds(prevIds => [...prevIds, id]);
        } else {
            setSelectedUserIds(prevIds => prevIds.filter(currentId => currentId !== id));
        }
    };

    const updateArchivedUser = () => {
        selectedUserIds.forEach(userId => {
            update(ref(database, 'users/' + userId), {
                archived: true
            });
        });
    };

    return {
        users,
        handleCheckboxChange,
        updateArchivedUser
    };
}
