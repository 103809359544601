import { Routes, Route, BrowserRouter } from "react-router-dom"
import Master from './pages/master';  // Asegúrate de importar el componente NotFound
import Users from './pages/Users'
import UserDetails from "./pages/UserDetails";
import { useEffect } from "react";
import Cookies from "js-cookie";
import Archived from "./pages/Archiveds";
function App() {
  useEffect(() => {
    const colorMain = Cookies.get('main-color');
    const colorTitleBar = Cookies.get('window-title-bar');
    const colorWindowBackground = Cookies.get('window-background');
    const colorText = Cookies.get('text-color');
    const colorBgBoxes = Cookies.get('background-boxes');
    const colorUsersTableText = Cookies.get('background-boxes');
    if (colorMain) {
      document.documentElement.style.setProperty('--main-color', colorMain);
    }
    if (colorTitleBar) {
      document.documentElement.style.setProperty('--window-title-bar', colorTitleBar);
    }
    if (colorWindowBackground) {
      document.documentElement.style.setProperty('--window-background', colorWindowBackground);
    }
    if (colorText) {
      document.documentElement.style.setProperty('--text-color', colorText);
    }
    if (colorBgBoxes) {
      document.documentElement.style.setProperty('--background-boxes', colorBgBoxes);
    }
    if (colorUsersTableText) {
      document.documentElement.style.setProperty('--users-table-color', colorUsersTableText);
    }

  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Master />} />
        <Route path="/users" element={<Users />} />
        <Route path="/users/:id" element={<UserDetails />} />
        <Route path="/archiveds" element={<Archived />} />
        <Route path="/archiveds/:id" element={<UserDetails />} />
      </Routes>
    </BrowserRouter>
  );
}
export default App;
