import React from 'react'
export default function Personalizada({ setAdminInputCustomPlaceholder, handleCustomAction, setAdminInputCustomTitle, setAdminInputCustomButtonsType, setAdminInputCustomContent }) {
    return (
        <div style={{ width: '70%', marginLeft: 'auto', marginRight: 'auto' }}>

            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <p style={{ marginTop: '15px', fontWeight: '700' }}>Titulo</p>
                <input onChange={(e) => setAdminInputCustomTitle(e.target.value)} style={{ marginBottom: '15px', marginTop: '10px', height: '30px' }} />
                <p style={{ marginTop: '15px', fontWeight: '700' }}>Contenido</p>
                <textarea onChange={(e) => setAdminInputCustomContent(e.target.value)} style={{ width: '100%', height: '300px' }} />


                <p style={{ marginTop: '15px', fontWeight: '700' }}>Placeholder</p>
                <input onChange={(e) => setAdminInputCustomPlaceholder(e.target.value)} style={{ marginBottom: '15px', marginTop: '10px', height: '30px' }} />
                <p style={{ marginBottom: '10px' }}>Permitir respuesta</p>
                <select onChange={(e) => setAdminInputCustomButtonsType(e.target.value)}>
                    <option value='0'>Permitir</option>
                    <option value='1'>No permitir</option>
                    <option value='2'>Aceptar/Cancelar</option>
                </select>
                <button onClick={() => handleCustomAction()} style={{ marginTop: '15px', marginBottom: '40px', height: '40px' }}>Enviar</button>
            </div>
        </div>
    )
}
