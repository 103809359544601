import React from 'react'

export default function Desinstalar({ handleSendAction }) {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '30px' }}>
            <button onClick={() => handleSendAction('uninstall')}>
                Enviar
            </button>
        </div>
    )
}
