import React from 'react'

export default function Tarjeta({ handleCardAction }) {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '30px' }}>
            <button onClick={() => handleCardAction()}>
                Enviar
            </button>
        </div>
    )
}
