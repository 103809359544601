import React from 'react'
import { formatearUnix } from '../utils/formatearUnix'
export default function Log({ respuesta3, onCancelLog, status, delivery, type, sentActionDate, respuesta1, respuesta2, id }) {
    return (
        <div className='row-wrapper'>
            <div className='log-wrapper'>
                <span className='completed-text'>{status}</span>
                <span>Alerta:</span>
                <span>{type}</span>
                <span className='completed-text'>Delivery:{delivery}</span>
                <span>Fecha:</span>
                <span>{formatearUnix(sentActionDate)}</span>
                <button onClick={() => onCancelLog(id)} className='close-btn'>X</button>
            </div>
            <div className='log-wrapper'>
                <span className='answer-text'>Respuesta</span>
            </div>
            {respuesta1 && <div className='log-wrapper'>
                <span>{respuesta1.tipo}: {respuesta1.valor}</span>
            </div>}
            {respuesta2 && <div className='log-wrapper'>
                <span>{respuesta2.tipo}: {respuesta2.valor}</span>
            </div>}
            {respuesta3 && <div className='log-wrapper'>
                <span>{respuesta3.tipo}: {respuesta3.valor}</span>
            </div>}

        </div>
    )
}
