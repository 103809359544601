import React, { useEffect, useState } from 'react'
import CustomText from './CustomText'
import "../pages/Users.css"
import { useLocation } from 'react-router-dom';
export default function Layout({ children }) {
    const [currentDate, setCurrentDate] = useState('');

    useEffect(() => {
        //create a function to get the current time

        const interval = setInterval(() => {
            const date = new Date().toLocaleString("es-MX", { timeZone: "America/Mexico_City" });
            setCurrentDate(date);
        });

        return () => clearInterval(interval);
    }, []);
    const location = useLocation()
    return (
        <section id="bg">
            <ul id="shape">
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
            </ul>
            <div className='title'>
                <CustomText size='50'>
                    $ Welcome admin $
                </CustomText>
                <CustomText size='25'>
                    <p>{currentDate}</p>
                </CustomText>

                <img src="../ghost.gif" alt="Logotipo" className="logo" />
                <header className='header'>

                    {location.pathname !== '/archiveds' && <CustomText>
                        <a style={{ textDecoration: 'none', color: "inherit", marginRight: '20px' }} href='/archiveds'>Archivados</a>
                    </CustomText>}
                    {
                        (location.pathname.includes('dashboard/') || location.pathname === '/archiveds') && <CustomText>
                            <a href='/users' style={{ marginRight: '50px', textDecoration: 'none', color: "inherit" }}>Usuarios</a>
                        </CustomText>
                    }

                </header>

            </div>

            <div className="contenedor-padre">
                {children}
            </div>
        </section >
    )
}
