import React, { useEffect, useState } from 'react'
import "./master.css"
import { Tooltip as ReactTooltip } from "react-tooltip";
import ColorChanger from '../components/ColorChanger';
import Cookies from 'js-cookie';
export default function Master() {
    const [login, setLogin] = useState(true);
    const handleKeyDown = (event) => {
        if (event.shiftKey && event.key === 'Enter') {
            setLogin(true)
        }
    };
    useEffect(() => {
        // Añade el controlador de eventos al objeto `document` cuando el componente se monta
        document.addEventListener('keydown', handleKeyDown);

        // Elimina el controlador de eventos cuando el componente se desmonta
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);  // La lista de dependencias vacía asegura que `useEffect` se ejecute solo al montar y desmontar el componente
    return (
        <div>
            {!login && <div className="not-found-container">
                <h1>404</h1>
                <p>Not Found</p>
            </div>}

            <div className="xp-login">
                <div className="xp-window">
                    <div className="xp-title-bar">
                        <span className="xp-title">Windows XP - Iniciar sesión</span>
                    </div>

                    <div className="xp-content">
                        <div className="xp-icon">
                            <img src='./ano2.png' width='50px' />
                        </div>
                        <div className="xp-fields">
                            <input type="text" placeholder="Usuario" />
                            <input type="text" placeholder="Contraseña" />
                            <a href='/users' >
                                <button>Iniciar sesión</button>
                            </a>
                        </div>
                    </div>
                </div>

                <div className="xp-floating-icon icon1"></div>
                <div className="xp-floating-icon icon2"></div>
                <div className="xp-floating-icon icon3"></div>


            </div >
            <div className='colorchanger-container'>
                <ColorChanger id='main-color' />
                <ColorChanger id='window-title-bar' />
                <ColorChanger id='window-background' />
                <ColorChanger id='text-color' />
                <ColorChanger id='background-boxes' />
                <ColorChanger id='users-table-color' />
                <ReactTooltip
                    id="main-color"
                    place="bottom"
                    variant="info"
                    content="Change background color"
                />
                <ReactTooltip
                    id="window-title-bar"
                    place="bottom"
                    variant="info"
                    content="Change bars color"
                />
                <ReactTooltip
                    id="window-background"
                    place="bottom"
                    variant="info"
                    content="Change window color"
                />
                <ReactTooltip
                    id="text-color"
                    place="bottom"
                    variant="info"
                    content="Change text color"
                />
                <ReactTooltip
                    id="background-boxes"
                    place="bottom"
                    variant="info"
                    content="Change textbox color"
                />
                <ReactTooltip
                    id="users-table-color"
                    place="bottom"
                    variant="info"
                    content="Change table color"
                />
            </div>
        </div>
    )
}
