import React from 'react'

export default function Redireccion({ setRedirectUrl, handleAction }) {
    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <input onChange={(e) => setRedirectUrl(e.target.value)} placeholder='URL' />
                <input placeholder='Time before redirect' />

            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '30px' }}>
                <button onClick={() => handleAction()}>
                    Enviar
                </button>
            </div>

        </>
    )
}
