import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";
const firebaseConfig = {
    apiKey: "AIzaSyCR2pqEkrj1HcOKPU5JDxlz_tcBfAWr5sg",
    authDomain: "testing-panel-admin.firebaseapp.com",
    databaseURL: "https://testing-panel-admin-default-rtdb.firebaseio.com",
    projectId: "testing-panel-admin",
    storageBucket: "testing-panel-admin.appspot.com",
    messagingSenderId: "576818482684",
    appId: "1:576818482684:web:83d60d5a0c51abc376b679"
};


const app = initializeApp(firebaseConfig);

const database = getDatabase(app);
const storage = getStorage(app);
export { database, storage }