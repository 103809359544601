import React from 'react'
import "./Users.css"
import Layout from '../components/Layout';
import CustomText from '../components/CustomText';
import { useUsers } from '../hooks/useUsers';
export default function Users() {
    const { users, handleCheckboxChange, updateArchivedUser } = useUsers();

    function formatearFecha(unixTime) {
        const fecha = new Date(unixTime * 1000);
        const opciones = {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false // Para asegurarte de que sea formato de 24 horas
        };
        return fecha.toLocaleDateString('es-ES', opciones);
    }

    return (
        <>
            <Layout>
                <div style={{ marginBottom: '10px' }}>
                    <button onClick={updateArchivedUser}>
                        <CustomText>
                            Archivar Seleccionados
                        </CustomText>
                    </button>
                </div>
                <table className="xp-table">
                    <thead>
                        <tr>
                            <th>Select</th>
                            <th>ID</th>
                            {/* <th>Notes</th> */}
                            <th>IP</th>
                            {/* <th>Browser</th> */}
                            <th>Mail</th>
                            {/* <th>Active</th> */}
                            <th>Password</th>
                            <th>Online</th>
                            <th>Joined date</th>
                            <th>Archived</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user) => <tr>
                            <td key={user.id}><input type='checkbox' style={{ width: '10px' }} onChange={e => handleCheckboxChange(e, user.id)} /></td>
                            <td>{user.counterId}</td>
                            <td>{user.ip}</td>
                            <td>{user.email}</td>
                            <td>{user.password}</td>
                            <td>{user.online ? 'Yes' : 'No'}</td>
                            <td>{formatearFecha(user.date)}</td>
                            <td>{user.archived ? "Yes" : 'No'}</td>
                            <td><a href={`/users/${user.id}`}><img src='./lupita.png' width='50px' class='lupita-animada' alt='' /></a></td>
                        </tr>)}
                        <tr>
                        </tr>
                    </tbody>
                </table>
            </Layout>



        </>
    )
}
