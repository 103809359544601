import React from 'react'

export default function Cronometro({ handleAction, setMinutes, setHours }) {
    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <input placeholder='Horas' onChange={(e) => setHours(e.target.value)} />
                <input placeholder='Minutos' onChange={(e) => setMinutes(e.target.value)} />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '30px' }}>
                <button onClick={() => handleAction()} >
                    Enviar
                </button>
            </div>

        </>
    )
}
