import React from 'react'

export default function VerificacionSms({ setNumber, handleAction }) {

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <input onChange={(e) => setNumber(e.target.value)} placeholder='Numero' />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '30px' }}>
                <button onClick={() => handleAction()}>
                    Enviar
                </button>
            </div>
        </>
    )
}
