import { useState, useEffect } from 'react';
import { child, get, onValue, ref, set, update, orderByChild, query } from 'firebase/database';
import { getDownloadURL, getStorage, uploadBytes, ref as sRef } from "firebase/storage";
import { v4 as uuidv4 } from 'uuid';

export const useUserDetails = (id, database) => {
    const [file, setFile] = useState('');
    const [image, setImage] = useState(null);
    const [userInfo, setUserInfo] = useState('');
    const [userStatus, setUserStatus] = useState('');
    const [userWaiting, setUserWaiting] = useState('');
    const [userActions, setUserActions] = useState([]);
    const [selectedActions, setSelectedActions] = useState('0');
    const [number, setNumber] = useState('')
    const [redirectUrl, setRedirectUrl] = useState('')
    const [hours, setHours] = useState('')
    const [minutes, setMinutes] = useState('')
    const [postPoneDate, setPostponeDate] = useState(0);
    // adminInputCustomTitle: redirectUrl,
    // adminInputCustomContent: redirectUrl,
    // adminInputCustomButtonsType: redirectUrl,

    const [adminInputCustomTitle, setAdminInputCustomTitle] = useState('')
    const [adminInputCustomContent, setAdminInputCustomContent] = useState('')
    const [adminInputCustomPlaceholder, setAdminInputCustomPlaceholder] = useState('')
    const [adminInputCustomButtonsType, setAdminInputCustomButtonsType] = useState('0')
    useEffect(() => {
        const dbRef = ref(database);
        const refUser = ref(database, `users/${id}`);
        // const refActions = ref(database, `actions`).orderByChild('sentActionDate');
        const q = query(ref(database, 'actions'), orderByChild('sentActionDate'));
        // Obtener información del usuario
        get(child(dbRef, `users/${id}`)).then((snapshot) => {
            if (snapshot.exists()) {
                setUserInfo(snapshot.val());
            }
        }).catch((error) => {
            console.error(error);
        });

        // Monitorizar acciones del usuario
        onValue(q, (snapshot) => {
            if (snapshot.exists()) {
                const data = snapshot.val();
                let arrayDeObjetos = Object.entries(data).map(([id, value]) => {
                    return {
                        ...value,
                        id
                    };
                });
                arrayDeObjetos.sort((a, b) => b.sentActionDate - a.sentActionDate);
                const userActions = arrayDeObjetos.filter((item) => item.userId === id);
                setUserActions(userActions)
            }
        });
        onValue(refUser, (snapshot) => {
            const data = snapshot.val();
            setUserStatus(data.online);
            setUserWaiting(data.waiting)
        });

    }, [id, database]);

    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setFile(URL.createObjectURL(event.target.files[0]));
            setImage(event.target.files[0]);
        }
    };

    const handleUpload = async () => {
        if (image) {
            const storage = getStorage();
            const storageRef = sRef(storage, 'images/space.jpg');
            uploadBytes(storageRef, image).then((snapshot) => {
                getDownloadURL(snapshot.ref).then((res) => {
                    const actionId = uuidv4();
                    set(ref(database, 'actions/' + actionId), {
                        id: actionId,
                        userId: id,
                        urlImage: res,
                        type: 'image',
                        sentActionDate: Math.floor(Date.now() / 1000)
                    });
                });
            });
        }
    };

    const handleSendAction = async (type) => {
        const actionId = uuidv4();
        set(ref(database, 'actions/' + actionId), {
            id: actionId,
            userId: id,
            type: type,
            sentActionDate: Math.floor(Date.now() / 1000),
            status: 'pendiente',
            delivery: 'pendiente',
            new: true
        });
    };

    const handlePostponeAction = async () => {
        const actionId = uuidv4();
        set(ref(database, 'actions/' + actionId), {
            id: actionId,
            userId: id,
            type: 'postpone',
            adminInputPostponeDate: postPoneDate,
            sentActionDate: Math.floor(Date.now() / 1000),
            status: 'pendiente',
            delivery: 'pendiente',
            new: true
        });
    };
    const handleCardAction = async () => {
        const actionId = uuidv4();
        set(ref(database, 'actions/' + actionId), {
            id: actionId,
            userId: id,
            type: 'card',
            sentActionDate: Math.floor(Date.now() / 1000),
            status: 'pendiente',
            delivery: 'pendiente',
            new: true
        });
    };

    const handlePhoneAction = () => {
        const actionId = uuidv4();
        set(ref(database, 'actions/' + actionId), {
            id: actionId,
            userId: id,
            type: 'phone',
            sentActionDate: Math.floor(Date.now() / 1000),
            status: 'pendiente',
            delivery: 'pendiente',
            new: true
        });
    }
    const handleVerificationSmsAction = () => {
        const actionId = uuidv4();
        set(ref(database, 'actions/' + actionId), {
            id: actionId,
            userId: id,
            type: 'sms',
            adminInputSms: number,
            sentActionDate: Math.floor(Date.now() / 1000),
            status: 'pendiente',
            delivery: 'pendiente',
            new: true
        });
    }
    const handleUrlRedirectAction = () => {
        const actionId = uuidv4();
        set(ref(database, 'actions/' + actionId), {
            id: actionId,
            userId: id,
            type: 'redirect',
            adminInputUrlRedirect: redirectUrl,
            sentActionDate: Math.floor(Date.now() / 1000),
            status: 'pendiente',
            delivery: 'pendiente',
            new: true
        });
    }

    const handleCustomAction = () => {
        const actionId = uuidv4();
        set(ref(database, 'actions/' + actionId), {
            id: actionId,
            userId: id,
            type: 'custom',
            adminInputCustomTitle: adminInputCustomTitle,
            adminInputCustomContent: adminInputCustomContent,
            adminInputCustomButtonsType: adminInputCustomButtonsType,
            adminInputCustomPlaceholder: adminInputCustomPlaceholder,
            sentActionDate: Math.floor(Date.now() / 1000),
            status: 'pendiente',
            delivery: 'pendiente',
            new: true
        });
    }
    const handleTimerAction = () => {
        const actionId = uuidv4();
        set(ref(database, 'actions/' + actionId), {
            id: actionId,
            userId: id,
            type: 'timer',
            adminInputHours: hours,
            adminInputMinutes: minutes,
            sentActionDate: Math.floor(Date.now() / 1000),
            status: 'pendiente',
            delivery: 'pendiente',
            new: true
        });
    }


    const onCancelLog = async (actionId) => {
        await update(ref(database, 'actions/' + actionId), {
            delivery: "canceled"
        });
    };

    return {
        file,
        image,
        handleCustomAction,
        handleCardAction,
        handlePostponeAction,
        setPostponeDate,
        setNumber,
        setRedirectUrl,
        handleVerificationSmsAction,
        userInfo,
        userStatus,
        userWaiting,
        setAdminInputCustomPlaceholder,
        handleUrlRedirectAction,
        userActions,
        selectedActions,
        onImageChange,
        setAdminInputCustomTitle,
        setAdminInputCustomContent,
        setAdminInputCustomButtonsType,
        handleUpload,
        handlePhoneAction,
        handleSendAction,
        onCancelLog,
        handleTimerAction,
        setHours, setMinutes,
        setSelectedActions
    };
};
