import React, { useEffect, useState } from 'react';
import './ColorChanger.css';
import Cookies from 'js-cookie';
const ColorChanger = ({ id }) => {
    const [color, setColor] = useState(() => {
        // Intentamos obtener el color de la cookie al inicializar el estado.
        return Cookies.get(id) || "#000";
    });
    const handleColorChange = (event) => {
        const newColor = event.target.value;
        setColor(newColor);
        Cookies.set(id, newColor, { expires: 365 });
    };


    return (
        <div>
            <div className="colorPickerContainer">
                <input
                    data-tooltip-id={id}
                    type="color"
                    value={color}
                    onChange={handleColorChange}
                />
            </div>
        </div>
    );
};

export default ColorChanger;